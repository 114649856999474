import React from "react";
import OwlCarousel from "react-owl-carousel";
import {
  AboutOneImg,
  AboutTwoImg,
  GalleryFive,
  GalleryFour,
  GalleryOne,
  GallerySix,
  GalleryThree,
  GalleryTwo,
  HomeContactBG,
  ProductEight,
  ProductFive,
  ProductFour,
  ProductNine,
  ProductOne,
  ProductSeven,
  ProductSix,
  ProductThree,
  ProductTwo,
  Quality1,
  Quality10,
  Quality11,
  Quality12,
  Quality14,
  Quality15,
  Quality16,
  Quality17,
  Quality18,
  Quality19,
  Quality2,
  Quality20,
  Quality21,
  Quality22,
  Quality23,
  Quality24,
  Quality25,
  Quality3,
  Quality4,
  Quality5,
  Quality6,
  Quality7,
  Quality8,
  Quality9,
  QualityControlBg,
  SliderOne,
  SliderThree,
  SliderTwo,
  YoutubeBg,
} from "../Images";
import { Link } from "react-router-dom";

const Home = () => {
  //Banner
  const doAnimations = (elements) => {
    elements.forEach((el) => {
      const animationDelay = el.dataset.delay || "0s";
      const animationDuration = el.dataset.duration || "1s";
      const animationType = `animated ${el.dataset.animation || "fadeIn"}`;

      el.style.animationDelay = animationDelay;
      el.style.webkitAnimationDelay = animationDelay;
      el.style.animationDuration = animationDuration;
      el.style.webkitAnimationDuration = animationDuration;

      el.classList.add(...animationType.split(" "));
      el.addEventListener("animationend", () => {
        el.classList.remove(...animationType.split(" "));
      });
    });
  };

  const banner = {
    loop: true,
    margin: 0,
    nav: true,
    dots: false,
    autoplay: true,
    autoplayTimeout: 5000,
    autoplayHoverPause: true,
    items: 1,
    navText: [
      "<i class='fas fa-arrow-left'></i>",
      "<i class='fas fa-arrow-right'></i>",
    ],
    onInitialized: (event) => {
      doAnimations(event.target.querySelectorAll("[data-animation]"));
    },
    onChanged: (event) => {
      doAnimations(event.target.querySelectorAll("[data-animation]"));
    },
  };

  // Portfolio Slider
  const options = {
    loop: true,
    margin: 30,
    nav: true,
    dots: false,
    autoplay: true,
    autoplayHoverPause: true,
    navText: [
      "<i class='fa fa-arrow-left'></i>",
      "<i class='fa fa-arrow-right'></i>",
    ],
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 2,
      },
      1000: {
        items: 3,
      },
    },
  };
  return (
    <>
      <div className="hero-section">
        <div className="hero-slider">
          <OwlCarousel className="hero-slider" {...banner}>
            <div
              className="hero-single"
              style={{ backgroundImage: `url(${SliderOne})` }}
            >
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-md-12 col-lg-7">
                    <div className="hero-content">
                      <h6
                        className="hero-sub-title"
                        data-animation="fadeInUp"
                        data-delay=".25s"
                      >
                        Welcome To ESSEX marine
                      </h6>
                      <h1
                        className="hero-title"
                        data-animation="fadeInRight"
                        data-delay=".50s"
                      >
                        Vannamei Shrimp
                      </h1>
                      <div
                        className="hero-btn"
                        data-animation="fadeInUp"
                        data-delay="1s"
                      >
                        <Link to="/about-us" className="theme-btn">
                          About More<i className="fas fa-arrow-right"></i>
                        </Link>
                        <Link to="/contact-us" className="theme-btn theme-btn2">
                          Contact Us<i className="fas fa-arrow-right"></i>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="hero-single"
              style={{ backgroundImage: `url(${SliderTwo})` }}
            >
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-md-12 col-lg-7">
                    <div className="hero-content">
                      <h6
                        className="hero-sub-title"
                        data-animation="fadeInUp"
                        data-delay=".25s"
                      >
                        Welcome To ESSEX marine
                      </h6>
                      <h1
                        className="hero-title"
                        data-animation="fadeInRight"
                        data-delay=".50s"
                      >
                        Pomfret
                      </h1>
                      <div
                        className="hero-btn"
                        data-animation="fadeInUp"
                        data-delay="1s"
                      >
                        <Link to="/about-us" className="theme-btn">
                          About More<i className="fas fa-arrow-right"></i>
                        </Link>
                        <Link to="/contact-us" className="theme-btn theme-btn2">
                          Contact Us<i className="fas fa-arrow-right"></i>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="hero-single"
              style={{ backgroundImage: `url(${SliderThree})` }}
            >
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-md-12 col-lg-7">
                    <div className="hero-content">
                      <h6
                        className="hero-sub-title"
                        data-animation="fadeInUp"
                        data-delay=".25s"
                      >
                        Welcome To ESSEX marine
                      </h6>
                      <h1
                        className="hero-title"
                        data-animation="fadeInRight"
                        data-delay=".50s"
                      >
                        Sea Caught Shrimp
                      </h1>
                      <div
                        className="hero-btn"
                        data-animation="fadeInUp"
                        data-delay="1s"
                      >
                        <Link to="/about-us" className="theme-btn">
                          About More<i className="fas fa-arrow-right"></i>
                        </Link>
                        <Link to="/contact-us" className="theme-btn theme-btn2">
                          Contact Us<i className="fas fa-arrow-right"></i>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </OwlCarousel>
        </div>
      </div>
      <div className="about-area py-120 mb-40">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="about-left">
                <div className="about-img">
                  <img className="about-img-1" src={AboutOneImg} alt="" />
                  <img className="about-img-2" src={AboutTwoImg} alt="" />
                </div>
                <div className="about-experience">
                  <span>25</span>
                  <h5>
                    Years Of <br /> Experience
                  </h5>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="about-right">
                <div className="site-heading mb-3">
                  <span className="site-title-tagline">About Us</span>
                  <h2 className="site-title">
                    Explore Your <span>Exciting Fishing</span> World With Us.
                  </h2>
                </div>
                <p className="about-text">
                  Essex Marine Pvt Ltd, Midnapur, West Bengal is a modern
                  seafood processing and exporting company, promoted by Sri.
                  Debashish Sen, the Managing Director. The Registered office of
                  the company is at 19, Pollock Street, Kolkata 700 001, WB. The
                  objective of the company is to procure, process, store and
                  export their safe and quality fish and aquaculture products to
                  all the countries including European Union. The company is
                  designed with all modern facilities in line with
                  specifications of national and importing countries.
                </p>
                <p className="about-text">
                  The Company is installed with the state-of-the-art machineries
                  and equipments.
                  {/* Our processing and Freezing capacity. */}
                </p>
                <p className="about-text">
                  The company is constructed in an ideal unpolluted location
                  having an advantages of availability of drinking water,
                  skilled manpower, required electricity, uninterrupted
                  communication system, near by port connected with wide NH-
                  highways, abundance of wholesome raw materials and other
                  in-puts.
                </p>
                <a href="about.html" className="theme-btn">
                  Discover More<i className="fas fa-arrow-right"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="video-area">
        <div className="container-fluid px-0">
          <div
            className="video-content"
            style={{ backgroundImage: `url(${YoutubeBg})` }}
          >
            <div className="row align-items-center">
              <div className="col-lg-12">
                <div className="video-wrapper">
                  <a
                    className="play-btn popup-youtube"
                    href="https://www.youtube.com/"
                  >
                    <i className="fas fa-play"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="portfolio-area py-120">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 mx-auto">
              <div
                className="site-heading text-center wow fadeInDown"
                data-wow-delay=".25s"
              >
                <span className="site-title-tagline">Product</span>
                <h2 className="site-title">
                  Our Exclusive <span>Products</span>
                </h2>
                <div className="heading-divider"></div>
                {/* <p>
                  Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                  Doloremque eos deleniti sit fugit soluta at!
                </p> */}
              </div>
            </div>
          </div>
          <div className="row popup-gallery wow fadeInUp" data-wow-delay=".25s">
            <div className="portfolio-slider">
              <OwlCarousel {...options}>
                <div className="portfolio-item">
                  <div className="portfolio-img">
                    <img className="img-fluid" src={ProductOne} alt="" />
                    <Link className="popup-img portfolio-link" to="/product">
                      <i className="fa fa-plus"></i>
                    </Link>
                  </div>
                  <div className="portfolio-content">
                    <div className="portfolio-content-info">
                      <small>FROZEN EEL</small>
                    </div>
                    <Link to="/product" className="portfolio-arrow">
                      <i className="fa fa-arrow-right"></i>
                    </Link>
                  </div>
                </div>
                <div className="portfolio-item">
                  <div className="portfolio-img">
                    <img className="img-fluid" src={ProductTwo} alt="" />
                    <Link className="popup-img portfolio-link" to="/product">
                      <i className="fa fa-plus"></i>
                    </Link>
                  </div>
                  <div className="portfolio-content">
                    <div className="portfolio-content-info">
                      <small>WHOLE CLEAN SQUID</small>
                    </div>
                    <Link to="/product" className="portfolio-arrow">
                      <i className="fa fa-arrow-right"></i>
                    </Link>
                  </div>
                </div>
                <div className="portfolio-item">
                  <div className="portfolio-img">
                    <img className="img-fluid" src={ProductThree} alt="" />
                    <Link className="popup-img portfolio-link" to="/product">
                      <i className="fa fa-plus"></i>
                    </Link>
                  </div>
                  <div className="portfolio-content">
                    <div className="portfolio-content-info">
                      <small>SEA CAUGHT SHRIMP</small>
                    </div>
                    <Link to="/product" className="portfolio-arrow">
                      <i className="fa fa-arrow-right"></i>
                    </Link>
                  </div>
                </div>
                <div className="portfolio-item">
                  <div className="portfolio-img">
                    <img className="img-fluid" src={ProductFour} alt="" />
                    <Link className="popup-img portfolio-link" to="/product">
                      <i className="fa fa-plus"></i>
                    </Link>
                  </div>
                  <div className="portfolio-content">
                    <div className="portfolio-content-info">
                      <small>SEA CAUGHT SHRIMP</small>
                    </div>
                    <Link to="/product" className="portfolio-arrow">
                      <i className="fa fa-arrow-right"></i>
                    </Link>
                  </div>
                </div>
                <div className="portfolio-item">
                  <div className="portfolio-img">
                    <img className="img-fluid" src={ProductFive} alt="" />
                    <Link className="popup-img portfolio-link" to="/product">
                      <i className="fa fa-plus"></i>
                    </Link>
                  </div>
                  <div className="portfolio-content">
                    <div className="portfolio-content-info">
                      <small>FROZEN SOLE FISH</small>
                    </div>
                    <Link to="/product" className="portfolio-arrow">
                      <i className="fa fa-arrow-right"></i>
                    </Link>
                  </div>
                </div>
                <div className="portfolio-item">
                  <div className="portfolio-img">
                    <img className="img-fluid" src={ProductSix} alt="" />
                    <Link className="popup-img portfolio-link" to="/product">
                      <i className="fa fa-plus"></i>
                    </Link>
                  </div>
                  <div className="portfolio-content">
                    <div className="portfolio-content-info">
                      <small>FROZEN SLAB</small>
                    </div>
                    <Link to="/product" className="portfolio-arrow">
                      <i className="fa fa-arrow-right"></i>
                    </Link>
                  </div>
                </div>
                <div className="portfolio-item">
                  <div className="portfolio-img">
                    <img className="img-fluid" src={ProductSeven} alt="" />
                    <Link className="popup-img portfolio-link" to="/product">
                      <i className="fa fa-plus"></i>
                    </Link>
                  </div>
                  <div className="portfolio-content">
                    <div className="portfolio-content-info">
                      <small>FROZEN POMFRET</small>
                    </div>
                    <Link to="/product" className="portfolio-arrow">
                      <i className="fa fa-arrow-right"></i>
                    </Link>
                  </div>
                </div>
                <div className="portfolio-item">
                  <div className="portfolio-img">
                    <img className="img-fluid" src={ProductEight} alt="" />
                    <Link className="popup-img portfolio-link" to="/product">
                      <i className="fa fa-plus"></i>
                    </Link>
                  </div>
                  <div className="portfolio-content">
                    <div className="portfolio-content-info">
                      <small>POMFRET</small>
                    </div>
                    <Link to="/product" className="portfolio-arrow">
                      <i className="fa fa-arrow-right"></i>
                    </Link>
                  </div>
                </div>
                <div className="portfolio-item">
                  <div className="portfolio-img">
                    <img className="img-fluid" src={ProductNine} alt="" />
                    <Link className="popup-img portfolio-link" to="/product">
                      <i className="fa fa-plus"></i>
                    </Link>
                  </div>
                  <div className="portfolio-content">
                    <div className="portfolio-content-info">
                      <small>BLANCHED VANNAMEI</small>
                    </div>
                    <Link to="/product" className="portfolio-arrow">
                      <i className="fa fa-arrow-right"></i>
                    </Link>
                  </div>
                </div>
              </OwlCarousel>
            </div>
          </div>
        </div>
      </div>
      <div
        className="choose-area"
        style={{ backgroundImage: `url(${Quality1})` }}
      >
        <div className="container-fluid px-0">
          <div className="row">
            <div className="col-lg-7">
              <div className="choose-content">
                <div className="choose-content-info">
                  <div className="site-heading mb-0">
                    <h2 className="site-title mb-10">
                      Quality <span>Control</span>
                    </h2>
                    <p>
                      Essex Marine Private Limited, West Bengal, fully complies
                      with its quality policy adhering to the following
                      objectives:
                    </p>
                  </div>
                  <div className="choose-content-wrap">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="choose-item mt-0">
                          <div className="choose-item-icon mb-0">
                            <i className="fa fa-arrow-right"></i>
                          </div>
                          <div className="choose-item-info">
                            <p className="mb-0">
                              By strictly implementing the HACCP system
                              standards to achieve zero defect end products.
                            </p>
                          </div>
                        </div>
                        <div className="choose-item mt-1">
                          <div className="choose-item-icon mb-0">
                            <i className="fa fa-arrow-right"></i>
                          </div>
                          <div className="choose-item-info">
                            <p className="mb-0">
                              Developing and implementing Good Manufacturing
                              Practises GMP of building, surroundings,
                              equipment, layout and design, plant facilities,
                              personnel practises, pest control, warehouse
                              practises and operation.
                            </p>
                          </div>
                        </div>
                        <div className="choose-item mt-1">
                          <div className="choose-item-icon mb-0">
                            <i className="fa fa-arrow-right"></i>
                          </div>
                          <div className="choose-item-info">
                            <p className="mb-0">
                              A written sanitation programme based on the
                              Sanitation Standard Operating Procedures (SSOP)
                              detailed in the system standards.
                            </p>
                          </div>
                        </div>
                        <div className="choose-item mt-1">
                          <div className="choose-item-icon mb-0">
                            <i className="fa fa-arrow-right"></i>
                          </div>
                          <div className="choose-item-info">
                            <p className="mb-0">
                              Ensuring GMP and SSOP are constantly monitored
                              through a system of monitoring by an expert team
                              and maintaining the records.
                            </p>
                          </div>
                        </div>
                        <div className="choose-item mt-1">
                          <div className="choose-item-icon mb-0">
                            <i className="fa fa-arrow-right"></i>
                          </div>
                          <div className="choose-item-info">
                            <p className="mb-0">
                              Commitment of the management to effectively
                              implement HACCP and FSSC : 22,000 – V: 5 systems,
                              by continuous training of the personnel, to ensure
                              the consistency of the quality and safety of each
                              consignment before its shipment and also the
                              liability towards the customers.
                            </p>
                          </div>
                        </div>
                        <div className="choose-item mt-1">
                          <div className="choose-item-icon mb-0">
                            <i className="fa fa-arrow-right"></i>
                          </div>
                          <div className="choose-item-info">
                            <p className="mb-0">
                              By enlisting verification and audit from
                              technically competent independent external
                              agencies like EIA, MPEDA to periodic review of the
                              effective implementation of HACCP safety standard
                              systems.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="destination-area py-120">
        <div className="container">
          <div className="row">
            <div className="col-lg-7 mx-auto">
              <div
                className="site-heading text-center wow fadeInDown"
                data-wow-delay=".25s"
              >
                <span className="site-title-tagline">Destination</span>
                <h2 className="site-title">
                  Our <span>Gallery</span>
                </h2>
                <div className="heading-divider"></div>
              </div>
            </div>
          </div>
          <div className="row g-4">
            <div className="col-md-12 col-lg-6">
              <Link
                className="destination-item wow fadeInUp"
                data-wow-delay=".25s"
              >
                <div className="destination-img">
                  <img src={GalleryOne} alt="" />
                </div>
              </Link>
            </div>
            <div className="col-md-6 col-lg-3">
              <Link
                className="destination-item wow fadeInUp"
                data-wow-delay=".25s"
              >
                <div className="destination-img">
                  <img src={GalleryTwo} alt="" />
                </div>
              </Link>
            </div>
            <div className="col-md-6 col-lg-3">
              <Link
                className="destination-item wow fadeInUp"
                data-wow-delay=".25s"
              >
                <div className="destination-img">
                  <img src={GalleryThree} alt="" />
                </div>
              </Link>
            </div>
            <div className="col-md-6 col-lg-3">
              <Link
                className="destination-item wow fadeInUp"
                data-wow-delay=".25s"
              >
                <div className="destination-img">
                  <img src={GallerySix} alt="" />
                </div>
              </Link>
            </div>
            <div className="col-md-6 col-lg-3">
              <Link
                className="destination-item wow fadeInUp"
                data-wow-delay=".25s"
              >
                <div className="destination-img">
                  <img src={GalleryFive} alt="" />
                </div>
              </Link>
            </div>
            <div className="col-md-12 col-lg-6">
              <Link
                className="destination-item wow fadeInUp"
                data-wow-delay=".25s"
              >
                <div className="destination-img">
                  <img src={GalleryFour} alt="" />
                </div>
              </Link>
            </div>

            <div className="col-md-12 col-lg-6">
              <Link
                className="destination-item wow fadeInUp"
                data-wow-delay=".25s"
              >
                <div className="destination-img">
                  <img src={Quality10} alt="" />
                </div>
              </Link>
            </div>
            <div className="col-md-12 col-lg-6">
              <Link
                className="destination-item wow fadeInUp"
                data-wow-delay=".25s"
              >
                <div className="destination-img">
                  <img src={Quality11} alt="" />
                </div>
              </Link>
            </div>
            <div className="col-md-12 col-lg-6">
              <Link
                className="destination-item wow fadeInUp"
                data-wow-delay=".25s"
              >
                <div className="destination-img">
                  <img src={Quality12} alt="" />
                </div>
              </Link>
            </div>
            <div className="col-md-12 col-lg-6">
              <Link
                className="destination-item wow fadeInUp"
                data-wow-delay=".25s"
              >
                <div className="destination-img">
                  <img src={Quality23} alt="" />
                </div>
              </Link>
            </div>
            <div className="col-md-12 col-lg-6">
              <Link
                className="destination-item wow fadeInUp"
                data-wow-delay=".25s"
              >
                <div className="destination-img">
                  <img src={Quality14} alt="" />
                </div>
              </Link>
            </div>
            <div className="col-md-12 col-lg-6">
              <Link
                className="destination-item wow fadeInUp"
                data-wow-delay=".25s"
              >
                <div className="destination-img">
                  <img src={Quality15} alt="" />
                </div>
              </Link>
            </div>
            <div className="col-md-12 col-lg-6">
              <Link
                className="destination-item wow fadeInUp"
                data-wow-delay=".25s"
              >
                <div className="destination-img">
                  <img src={Quality16} alt="" />
                </div>
              </Link>
            </div>
            <div className="col-md-12 col-lg-6">
              <Link
                className="destination-item wow fadeInUp"
                data-wow-delay=".25s"
              >
                <div className="destination-img">
                  <img src={Quality17} alt="" />
                </div>
              </Link>
            </div>
            <div className="col-md-12 col-lg-6">
              <Link
                className="destination-item wow fadeInUp"
                data-wow-delay=".25s"
              >
                <div className="destination-img">
                  <img src={Quality18} alt="" />
                </div>
              </Link>
            </div>
            <div className="col-md-12 col-lg-6">
              <Link
                className="destination-item wow fadeInUp"
                data-wow-delay=".25s"
              >
                <div className="destination-img">
                  <img src={Quality19} alt="" />
                </div>
              </Link>
            </div>
            <div className="col-md-12 col-lg-6">
              <Link
                className="destination-item wow fadeInUp"
                data-wow-delay=".25s"
              >
                <div className="destination-img">
                  <img src={Quality20} alt="" />
                </div>
              </Link>
            </div>
            <div className="col-md-12 col-lg-6">
              <Link
                className="destination-item wow fadeInUp"
                data-wow-delay=".25s"
              >
                <div className="destination-img">
                  <img src={Quality21} alt="" />
                </div>
              </Link>
            </div>
            <div className="col-md-12 col-lg-6">
              <Link
                className="destination-item wow fadeInUp"
                data-wow-delay=".25s"
              >
                <div className="destination-img">
                  <img src={Quality22} alt="" />
                </div>
              </Link>
            </div>
            <div className="col-md-12 col-lg-6">
              <Link
                className="destination-item wow fadeInUp"
                data-wow-delay=".25s"
              >
                <div className="destination-img">
                  <img src={Quality23} alt="" />
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="process-area bg py-80">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 mx-auto">
              <div className="site-heading text-center">
                <span className="site-title-tagline">Working Process</span>
                <h2 className="site-title">
                  How Our <span>Essex</span> Works
                </h2>
                <div className="heading-divider"></div>
                {/* <p>
                  Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                  Doloremque eos deleniti sit fugit soluta at!
                </p> */}
              </div>
            </div>
          </div>
          <div className="row g-4 justify-content-between">
            <div className="col-lg-4 col-md-6 text-center">
              <div className="process-single">
                <div className="icon">
                  <span>01</span>
                  <i className="fa-solid fa-fish"></i>
                </div>
                <h4>Good Manufacturing Practises</h4>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 text-center">
              <div className="process-single">
                <div className="icon">
                  <span>02</span>
                  <i className="fa-solid fa-faucet-drip"></i>
                </div>
                <h4>Sanitation Standard Operating Procedures</h4>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 text-center">
              <div className="process-single">
                <div className="icon">
                  <span>03</span>
                  <i className="fa-brands fa-google"></i>
                </div>
                <h4>British Retail Consortium</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="cta-area"
        style={{ backgroundImage: `url(${HomeContactBG})` }}
      >
        <div className="container">
          <div className="row">
            <div className="cta-content">
              <h1>Contact Us</h1>
              <p>
                19, POLLOCK STREET, 7TH FLOOR, ROOM NO:7 KOLKATA - 700001
                <br /> <Link to="tel:03322627928">03322627928</Link>{" "}
                <Link to="tel:+91 9073063500">+91 9073063500</Link>{" "}
                <Link to="mailto:kolkata.essex@gmail.com">
                  kolkata.essex@gmail.com
                </Link>
              </p>
              <a href="/contact-us" className="theme-btn">
                Contact Now<i className="fas fa-arrow-right"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
